import Vue from 'vue'
import * as AntDes from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import moment from 'moment-timezone'
import VueLazyLoad from 'vue-lazyload'
import App from '@/App.vue'
import '@/utils/filter'
import router from '@/router/router'
import store from '@/store/index'
import { i18n } from '@/i18n/i18n'
import { LoginController } from '@/services/request.service'
import {
  aDownload,
  getLocale,
  setLocale,
  getSchoolList,
  setSchoolList,
  setLanguage,
} from '@/utils/utils'
import SvgIcon from '@/components/SvgIcon.vue'
import 'simditor/styles/simditor.css'
import '@/components/simditor/style.less'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import config from '../package.json'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

import xss, { escapeAttrValue } from 'xss'
import baiduAnalytics from 'vue-baidu-analytics'

Vue.prototype.xss = html =>
  xss(html, {
    onIgnoreTagAttr: function(tag, name, value, isWhiteAttr) {
      if (name === 'style') {
        // 通过内置的escapeAttrValue函数来对属性值进行转义
        return name + '="' + escapeAttrValue(value) + '"'
      }
    },
  })

moment.tz.setDefault('Asia/Shanghai')

Vue.use(AntDes)

Vue.component('SvgIcon', SvgIcon)

Vue.use(VueLazyLoad, {
  loading: require('@/assets/images/loading.gif'),
})


Vue.use(baiduAnalytics, {
  router: router,
  siteIdList: [],
  isDebug: false,
})

Vue.prototype.$info = AntDes.Modal.info
Vue.prototype.$error = AntDes.Modal.error
Vue.prototype.$confirm = AntDes.Modal.confirm
Vue.prototype.$message = AntDes.message
Vue.prototype.$notification = AntDes.notification
Vue.prototype.$document = document
Vue.prototype.$aDownload = aDownload
Vue.config.productionTip = false

declare module 'vue/types/vue' {
  interface Vue {
    $document: typeof document
  }
}
Vue.use(Viewer, {
  defaultOptions: {
    title: false,
  },
})

const isPc = !navigator.userAgent.toLowerCase().includes('mobile')
store.commit('setIsPc', isPc)
if (!isPc) {
  window.location.href = window.location.origin + '/mobile/login'
}
setLanguage()


if (!getSchoolList() || !getSchoolList().length) {
  LoginController.getAllSchools()
    .then(res => {
      setSchoolList(res ? res.data : [])
      new Vue({
        router,
        store,
        i18n,
        render: h => h(App),
      }).$mount('#app')
    })
    .catch(err => {
      console.error(err)
      // window.location.reload()
    })
} else {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}
